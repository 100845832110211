import { ChatQuery } from '@/chat-strategies/hooks/usePluginChat/types'
import { HttpOption, downloadFile, get, post } from '@/utils/request'
import { ky } from '@/utils/request/axios'

import { AxiosProgressEvent, GenericAbortSignal } from 'axios'
/**
 * @description 获取技能列表
 */
export function fetchSkillList<T = any>(data: { page: number; pageSize: number }) {
  return get<T>({
    url: `/llm/skillCenter/getSkillList`,
    data,
  })
}

/**
 * @description 意图识别请求
 */
export function fetchIntentRecognition<T = any>(
  data: { word: string },
  signal?: GenericAbortSignal,
) {
  return get<T>({
    url: `/AI/wake/wakePages`,
    data,
    signal,
  })
}

/**
 * @description DbCHat 请求
 */
export function fetchChatAPI1<T = any>(
  data: {
    question: string
    socketIOClientId: string
    chatFlowId: string
    history?: { message: string | undefined; type: IChatRole }[]
    [key: string]: any
  },
  signal?: GenericAbortSignal,
) {
  return post<T>({
    url: `/llm/skillCenter/getExecuteChatFlowUrlBySession`,
    data,
    signal,
  })
}

/**
 * @description DbCHat 请求
 */
export function fetchChatAPI<T = any>(
  data: {
    question: string
    socketIOClientId: string | null
    chatFlowId: string
    history?: { message: string | undefined; type: IChatRole }[]
    [key: string]: any
  },
  signal?: GenericAbortSignal,
) {
  // FormData序列化
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    // 如果data[key]是object，则序列化成json字符串
    if (typeof data[key] === 'object' && key !== 'files') {
      formData.append(key, JSON.stringify(data[key]))
    } else if (key === 'files') {
      // 如果data[key]是files，则遍历files，将每个file添加到formData中
      data[key]?.forEach((file: File) => {
        formData.append(key, file)
      })
    } else {
      formData.append(key, data[key])
    }
  })
  return post<T>({
    url: `/llm/skillCenter/getExecuteChatFlowUrlBySessionFormData`,
    data: formData,
    signal,
  })
}
/**
 * @description 查询用户已订阅的技能
 */

export function getSubscribeChatFlow<T = any>(params: { userId: string | number; name: string }) {
  return get<T>({
    url: '/llm/skillCenter/getSubscribeChatFlow',
    data: params,
  })
}
/**
 * @description 获取默认技能
 */
export function getDefaultChatFlow<T = any>() {
  return get<T>({
    url: '/llm/skillCenter/getDefaultChatFlow',
  })
}
/**
 * @description 根据用户id查询数据源
 */
export function getDataSourceByUserId<T = any>(params: { userId: string | number }) {
  return get<T>({
    url: '/llm/skillCenter/getDataBaseById',
    data: { isAll: true, ...params },
  })
}

/**
 * @description  登录
 */
export function login<T = any>(data: { loginName: string; password: string }) {
  return get<T>({
    url: '/llm/auth/login',
    data,
  })
}
/**
 * @description 更新密码
 */
export function updatePassword<T = any>(data: { oldPassword: string; password: string }) {
  return post<T>({
    url: '/llm//pc/user/info/updatePassword',
    data,
  })
}

/**
 * @description  知识库文件上传
 */
export function uploadFile<T = any>(params: Partial<HttpOption>) {
  return post<T>({
    url: '/llm/skillCenter/pushFile',
    ...params,
  })
}

/**
 * @description  查询知识库文件
 */
export function getFileList<T = any>(params?: {
  parentId?: string
  fileName?: string
  shareStatus?: string
}) {
  return get<T>({
    url: '/llm/skillCenter/getPersonFolder',
    data: params,
  })
}

/**
 * @description 删除知识库文件
 */
export function deleteFile<T = any>(params: { ids: string }) {
  return get<T>({
    url: '/llm/skillCenter/delFolder',
    data: params,
  })
}

/**
 * @description 知识库文件下载
 */
export function downloadFiles(params: { fileId: string }, fileName: string) {
  return downloadFile(
    {
      url: '/AI/pc/person/knowledge/downPersonFile',
      params,
    },
    fileName,
  )
}
/**
 * @description 获取状态
 */
export function getStatus<T = any>(params: { fileIdList: string[] }) {
  return post<T>({
    url: '/llm/skillCenter/findFileStatus',
    data: params,
  })
}

/**
 * @description 查询合同审核预设问题
 */
export function getContractAuditQuestion<T = any>(params: any) {
  return get<T>({
    url: '/llm/skillCenter/contract/listPreQuestion',
    data: params,
  })
}
/**
 * @description 删除合同审核预设问题
 */
export function deleteContractAuditQuestion<T = any>(ids: string[]) {
  return post<T>({
    url: '/llm/skillCenter/contract/deletePreQuestion',
    data: ids,
  })
}

/**
 * @description 新增合同审核预设问题
 */
export function addContractAuditQuestion<T = any>(data: any) {
  return post<T>({
    url: '/llm/skillCenter/contract/insertPreQuestion',
    data,
  })
}

/**
 * @description 问题主题
 */
export function getQuestionTheme<T = any>() {
  return get<T>({
    url: '/llm/skillCenter/contract/getAllTopics',
  })
}

/**
 * @description text/event-stream 请求
 */
export function fetchChatAPIStream<T = any>(params: {
  data: ChatQuery
  signal?: GenericAbortSignal
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
}) {
  const { data, signal, onDownloadProgress } = params
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    // 如果data[key]是object，则序列化成json字符串
    if (typeof data[key] === 'object' && key !== 'files') {
      formData.append(key, JSON.stringify(data[key]))
    } else if (key === 'files') {
      // 如果data[key]是files，则遍历files，将每个file添加到formData中
      data[key]?.forEach((file: File) => {
        formData.append(key, file)
      })
    } else {
      formData.append(key, data[key])
    }
  })
  return post<T>({
    url: `/llm/skillCenter/plugin/chat/openai/formdata`,
    data: formData,
    signal,
    onDownloadProgress,
  })
}
export function fetchChatAPIStream1(params: { data: ChatQuery; signal?: AbortSignal }) {
  const { data, signal } = params
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    // 如果data[key]是object，则序列化成json字符串
    if (typeof data[key] === 'object' && key !== 'files') {
      formData.append(key, JSON.stringify(data[key]))
    } else if (key === 'files') {
      // 如果data[key]是files，则遍历files，将每个file添加到formData中
      data[key]?.forEach((file: File) => {
        formData.append(key, file)
      })
    } else {
      formData.append(key, data[key])
    }
  })
  return ky.post(`/llm/skillCenter/plugin/chat/openai/formdata`, formData, {
    signal,
  })
}
/**
 * @description  文生图
 */
export function textToImage<T = any>(data: { prompt: string; size: string }) {
  return post<T>({
    url: '/llm/skillCenter/plugin/text-to-image/openai',
    data,
  })
}

/**
 * @description  创建调用
 */
export function createCall<T = any>(data: Record<string, any>) {
  return post<T>({
    url: '/llm/skillCenter/plugin/gateway',
    data,
  })
}

/**
 * @description  查询客服历史消息
 */
export function getHumanChatHistory<
  T = { content: string; userName: string; [x: string]: any }[],
>(data: { userId: string }) {
  return get<T>({
    url: '/llm/socket/getMessageHistory',
    data,
  })
}

/**
 * @description  点赞点踩
 */
export function likeOrDislike<T = any>(data: {
  flag: 0 | 1
  chat_question: string
  chunk_ids: Array<number>
}) {
  return post<T>({
    url: '/llm/pk/v1/knowledge/recode_know',
    data,
  })
}

/**
 * @description  微信服务号授权回调，获取用户信息
 */
export function getUserInfoByWechat<T = any>(data: { code: string; state: string }) {
  return get<T>({
    url: '/llm/wechat/login',
    data,
  })
}

/**
 * @description  获取视图权限标识
 */
export function getViewPermission<T = any>() {
  return get<T>({
    url: '/llm/sys/config/component/list?status=1',
  })
}

/**
 * @description  获取执法记录仪视频流
 */
export function getVideoStream<T = any>(data: { deviceId: string }, signal?: GenericAbortSignal) {
  return get<T>({
    url: '/llm/video/getVideoStream',
    data,
    signal,
  })
}

export function getWarningData<T = any>(data: { deviceId: string }) {
  return get<T>({
    url: '/llm/video/getVideoStream',
    data,
  })
}
