/* eslint-disable camelcase */
import { textToImage, createCall, fetchChatAPIStream1 } from '@/api'
import { ChatHookFn, ChatHookParams, ChatParams } from '..'
import {
  getPluginTitle,
  getPluginDesc,
  getPluginNameByIdentifier,
  getMediaMessages,
} from './helpers'
import { genToolCallingName } from './toolCall'
import { storeToRefs } from 'pinia'
import { llmParamsStore } from '@/store'
import { ImageMimeTypes, MimeType } from '@/contants/mimeTypes'
import { fileToBase64, getBase64Image, isFileOrBlobInstance, isObject, isValidJson } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import type { ChatQuery, ImageSchema, Tool, ImageMessage } from './types'
/**
 *@description 插件模式会话逻辑
 */
export const usePluginChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  let controller = new AbortController()
  const { file } = storeToRefs(llmParamsStore())

  const activeIndex = ref<number | undefined>(undefined) //会话内容的索引, 默认是会后一条回答

  const isAllImage = computed(() =>
    file.value?.every((item) => ImageMimeTypes.includes(item.type as MimeType)),
  )
  /**
   * @description 发送(重试)会话信息
   * @param object {index: number} 重试回答是的信息所在索引
   */
  const sendMessage = async ({ index, loacl }: ChatHookParams = {}) => {
    controller = new AbortController()
    isAbort.value = false

    const { plugins, tools } = handleParmas()
    const str = enabledSysRoles(plugins)

    // 判断有没有会话上下文第一项是否又插件描述
    // if (activeTopic.value!.messages[0].role !== 'systemMessage') {
    //   activeTopic.value!.messages.unshift({
    //     date: new Date().toISOString(),
    //     role: 'systemMessage',
    //     content: str,
    //   })
    // }

    const len = activeTopic.value!.messages.length
    activeIndex.value = index ?? len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.content = '' // 清空回答,后面填充
    messages.status = 2 // 设置状态为回答中
    let pluginIdentifier = '' // 调用的插件名称(如果有)
    let pluginCallId = '' // 会话返回的pluginId(如果有)
    let pluginName = ''

    // 是否有图片资源
    const historyMsg = await getHistoryMsg(activeTopic.value!, activeIndex.value)
    // 获取files
    const files = !isAllImage.value ? file.value?.map((item) => item.file) || [] : []
    // 参数
    const params: ChatQuery = {
      question: message.value,
      frequency_penalty: 0,
      messages: historyMsg,
      model: 'gpt-4o',
      presence_penalty: 0,
      stream: true,
      temperature: 0.6,
      tools,
      top_p: 1,
      files,
      chatFlowId: activeAgent.value?.agentId,
    }
    message.value = ''
    let chunckIndex = 0
    let inValidChunk = ''

    const es = await fetchChatAPIStream1({
      data: params,
      signal: controller.signal,
    })
    messages.content = ''
    const reader = es.body?.pipeThrough(new TextDecoderStream()).getReader()
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const res = await reader?.read()
      if (res?.done) {
        inValidChunk = ''
        await Promise.all(timers.value.map(({ promise }) => promise))
        // 判断内容是否是空
        if (!messages.content) {
          messages.status = 1
          handleError('模型服务好像开小差了, 请重试!🚧🚧🚧', 404)
        } else {
          messages.status = 0
          processDoneChunk()
        }
        updateContent()
        break
      }

      const messageChunks = res?.value.split('data:').filter(Boolean) // 移除空字符串并直接切片
      for (const chunk of messageChunks || []) {
        try {
          chunckIndex++
          messages.status = 0 // 流式返回设置为0
          processRegularChunk(chunk)
        } catch (error) {
          inValidChunk += chunk
          if (isValidJson(inValidChunk)) {
            processRegularChunk(inValidChunk)
            inValidChunk = ''
          }
          // console.error('Error :', error)
        }
      }
    }
    /**
     * @description 错误处理
     * @param message 错误信息
     * @param code 错误码
     */
    function handleError(message: string, code: number) {
      messages.status = 1
      messages.content = message || '模型服务好像出小差了, 请重试!!🚧🚧🚧'
      updateContent()
    }

    /**
     * @description 流式接口返回处理
     * @param chunk 数据块
     */
    function processRegularChunk(chunk: any) {
      const data = JSON.parse(chunk)
      if (data.error) {
        handleError(data.error, 500)
      } else if (data.code === 401) {
        handleError('您的认证已失效，请重新登录', 401)
      } else {
        processDataChunk(data)
      }
    }

    /**
     * @description 处理流式数据块
     * @param data  data 数据块
     */
    function processDataChunk(data: any) {
      if (!data.choices) {
        return
      }
      const { tool_calls, content } = data.choices[0].delta || {}
      if (tool_calls) {
        // console.log(tool_calls)
        const {
          id,
          function: { name, arguments: args },
        } = tool_calls[0]
        name ? (pluginName = getPluginNameByIdentifier(name)[0]) : ''
        if (pluginName === 'moonlit-mind-map') {
          // 思维导图逻辑
          messages.content += args
          messages.plugin = pluginName
        } else {
          // 其他插件
          messages.status = 2
          name ? (pluginIdentifier = name) : ''
          id ? (pluginCallId = id) : ''
          messages.content += args
          messages.role = 'promptMessage'
        }
        scrollToBottom()
      } else {
        messages.status = !messages.content ? 2 : 0
        addTimer(() => {
          messages.content += content ?? ''
          scrollToBottom()
        }, 10 * chunckIndex)
      }
    }
    /**
     * @description 处理插件调用
     */
    function processDoneChunk() {
      if (pluginIdentifier) {
        const [pluginName] = getPluginNameByIdentifier(pluginIdentifier)
        if (pluginName === 'lobe-image-designer') {
          // 文生图逻辑
          const { prompts, size = '' } = JSON.parse(messages.content!)
          textToImageLogic(prompts, size)
        } else {
          handlePluginCall(pluginCallId, pluginIdentifier, messages.content!)
        }
      }
      pluginIdentifier = ''
    }
  }

  /**
   * @description 文生图逻辑
   */
  const textToImageLogic = async (prompts: string[], size: string) => {
    activeTopic.value!.messages.push({
      date: new Date().toISOString(),
      role: 'apiMessage',
      content: '生成图片中...',
      prompt: JSON.stringify(prompts),
      status: 0,
      mediaMessage: [] as unknown as MediaMessage<ImageSchema>[],
    })
    const len = activeTopic.value!.messages.length
    const topic = activeTopic.value!.messages[len - 1]

    const promises = prompts.map(async (prompt: string) => {
      topic.mediaMessage!.push({ type: 'image', data: { url: '', placeholder: prompt } })
      const res = await textToImage({ prompt, size })
      const resStr = res.replace('data:', '')
      try {
        const result = JSON.parse(resStr)
        if (!result.error) {
          const data_1 = result.data[0]
          const base64Str = await getBase64Image(data_1.url)
          topic.mediaMessage!.forEach((item: MediaMessage<ImageSchema>) => {
            if (item.data.placeholder === prompt) {
              item.data.url = base64Str
            }
          })
        } else {
          const { message } = result.error
          topic.mediaMessage!.forEach((item_1: MediaMessage<ImageSchema>) => {
            if (item_1.data.placeholder === prompt) {
              item_1.data.placeholder = prompt
              item_1.data.error = message
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
    scrollToBottom()
    await Promise.allSettled(promises)
    topic.content = '为你生成如下图片'
    updateTopic()
  }
  /**
   * @description 其他插件调用逻辑
   * @param id 插件调用id, 模型返回
   * @param pluginIdentifier 提问时手动生成的插件标识符
   * @param paramsStr 插件方法调用的参数字符串
   */
  const handlePluginCall = async (id: string, pluginIdentifier: string, paramsStr: string) => {
    const len = activeTopic.value!.messages.length
    const lastTopic = activeTopic.value!.messages[len - 1]
    lastTopic.role = 'apiMessage'
    lastTopic.status = 2

    const [pluginName, pluginFunctionName] = getPluginNameByIdentifier(pluginIdentifier)
    const apiName = pluginFunctionName
    const identifier = pluginName
    // const [manifest] = getMainfestByIdentifier(identifier)

    try {
      const res = await createCall({
        id,
        apiName,
        identifier,
        arguments: paramsStr,
        type: 'default',
      })
      if (res.code === 1) {
        switch (identifier) {
          case 'database-search':
            handleDatabaseSearch(res.data)
            break
          case 'knowledge-search':
            handleKnowledgeSearch(res.data)
            break
          case 'law-video':
            handleLawVideo(res.data)
            break
          case 'warn-search':
            handleWarnSearch(res.data)
            break
          default:
            // 原有的处理逻辑
            addMessage('toolMessage', {
              content: JSON.stringify(res.data),
              name: pluginIdentifier,
              tool_call_id: id,
            })
            addMessage('apiMessage', { prompt: JSON.stringify(res.data) })
            sendMessage()
        }
      } else {
        addMessage('apiMessage', {
          content: res.msg || '未知错误',
          status: 1,
        })
        updateTopic()
      }
    } catch (error: any) {
      addMessage('apiMessage', { content: JSON.stringify({ message: error.msg }), status: 1 })
      updateTopic()
    } finally {
      lastTopic.status = 0
      lastTopic.role = 'promptMessage'
    }
  }
  function handleDatabaseSearch(data: any) {
    const res = data[0]
    addMessage('apiMessage', { content: res.text, mediaMessage: getMediaMessages(res), status: 0 })
    updateTopic()
  }

  function handleKnowledgeSearch(data: any) {
    const text = data[0].text
    addMessage('apiMessage', { content: text, status: 0 })
    updateTopic()
  }

  function handleWarnSearch(data: any) {
    addMessage('apiMessage', {
      content: '',
      mediaMessage: [{ type: 'warn', data: '' }],
      status: 0,
    })
    updateTopic()
  }

  function handleLawVideo(data: any) {
    const res = data[0]
    const messages = {
      content: '',
      mediaMessage: [] as unknown as MediaMessage<ImageSchema>[],
    }
    if (res.type === '6') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'video',
        data: res,
      })
    } else if (res.type === '5') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'image',
        data: res,
      })
    } else if (res.type === '4') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'location',
        data: res,
      })
    }
    addMessage('apiMessage', { ...messages, status: 0 })
    updateTopic()
  }
  /**
   * @description 根据pluginSchema, 处理参数
   */
  const handleParmas = () => {
    const { pluginDetail } = activeAgent.value!
    let tools: Tool[] = []
    const plugins: PluginSchema[] = []
    if (pluginDetail) {
      try {
        const schemas: IPlugin[] = JSON.parse(pluginDetail).filter(Boolean)
        tools = schemas
          .map((item: any) => {
            const plugin: PluginSchema = JSON.parse(item.content)
            plugins.push(plugin)
            const functions = plugin?.api?.map((ele) => {
              return {
                function: {
                  name: `${plugin.identifier}__${ele.name}`,
                  description: ele.description,
                  parameters: ele.parameters,
                },
                type: 'function',
              }
            })
            return functions
          })
          .filter(Boolean)
          .flat(1)
      } catch (error) {
        console.error('插件参数解析失败', error)
      }
    }
    return { tools, plugins }
  }
  /**
   * @description 对话历史插入插件描述信息
   */
  const enabledSysRoles = (plugins: PluginSchema[]) => {
    const toolsSystemRole = plugins
      .map((plugin) => {
        const meta = plugin?.meta || {}
        const title = getPluginTitle(meta) || plugin?.identifier
        const systemRole = plugin?.systemRole || getPluginDesc(meta)
        const methods = plugin?.api
          .map((m) =>
            [
              `#### ${genToolCallingName(plugin?.identifier, m.name, plugin.type)}`,
              m.description,
            ].join('\n\n'),
          )
          .join('\n\n')

        return [`### ${title}`, systemRole, 'The APIs you can use:', methods].join('\n\n')
      })
      .filter(Boolean)
    if (toolsSystemRole.length > 0) {
      return ['## Tools', 'You can use these tools below:', ...toolsSystemRole]
        .filter(Boolean)
        .join('\n\n')
    }

    return ''
  }

  /**
   * @description 获取历史会话上下文
   * @param {ITopic} topicData
   */
  const getHistoryMsg = async (topicData: ITopic, index: number | undefined) => {
    const data = index !== undefined ? topicData.messages.slice(0, index) : topicData.messages
    const historyMsg = []
    for await (const item of data) {
      let ele
      if (item.attachments?.length) {
        const content = await parseImageMessage(item)
        ele = { content, role: item.role }
      } else if (item.role === 'toolMessage') {
        ele = {
          content: item.content,
          role: item.role,
          name: item.name,
          tool_call_id: item['tool_call_id'],
        }
      } else {
        ele = { content: item.content, role: item.role }
      }
      historyMsg.push(ele)
    }
    return historyMsg
  }
  const stopStream = () => {
    controller?.abort()
    const len = activeTopic.value!.messages.length
    activeIndex.value = activeIndex.value || len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')
    updateContent()
  }
  /**
   * @description 手动添加消息
   */
  const addMessage = (role: IChatRole, message: string | Record<string, any> = '') => {
    let messageItem: IChatMessage = {
      id: uuidv4(),
      role,
      content: '',
      date: new Date().toString(),
    }
    if (isObject(message)) {
      messageItem = Object.assign(messageItem, message)
    } else {
      messageItem.content = message
    }
    activeTopic.value!.messages.push(messageItem)
  }

  const getMainfestByIdentifier = (identifier: string) => {
    const { pluginDetail } = activeAgent.value!
    if (pluginDetail) {
      try {
        const plugins: IPlugin[] = JSON.parse(pluginDetail)
        return plugins.filter((plugin) => {
          const manifest = JSON.parse(plugin.content)
          return manifest.identifier === identifier
        })
      } catch (error) {}
    }
    return []
  }
  /**
   * @description 更新内容
   */
  const updateContent = async () => {
    isAbort.value = true
    clearAllTimers()
    file.value = []
    scrollToBottom()
    updateTopic()
  }
  const timers = ref<{ promise: Promise<void>; cancel: () => void }[]>([])

  function addTimer(callback: () => void, delay: number) {
    let timeoutId: number

    const promise = new Promise<void>((resolve) => {
      timeoutId = window.setTimeout(() => {
        callback()
        resolve()
      }, delay)
    })

    const cancel = () => clearTimeout(timeoutId)

    timers.value.push({ promise, cancel })
  }

  // 创建一个函数来清除所有定时器
  function clearAllTimers() {
    timers.value.forEach(({ cancel }) => cancel())
    timers.value = []
  }
  return { sendMessage, stopStream, name: 'plugin-chat' }
}
async function parseImageMessage(message: IChatMessage): Promise<ImageMessage[] | undefined> {
  const data: ImageMessage[] = []
  for await (const item of message!.attachments!) {
    if (ImageMimeTypes.includes(item.type)) {
      try {
        let base64Str = item.file
        if (isFileOrBlobInstance(item.file)) {
          base64Str = await fileToBase64(item.file)
        }
        data.push({
          image_url: {
            detail: 'auto',
            url: base64Str,
          },
          type: 'image_url',
        })
      } catch (error) {}
    }
    data.unshift({ text: message.content, type: 'text' })
    return data
  }
}
